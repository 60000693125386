import React from 'react';
import PropTypes from 'prop-types';

import HosmanProperties from '../components/HosmanProperties';
import { HosmanLogo } from 'hosman-material';

const SoldProperties = ({ sale, similarSalesScreenshots, comparableSales }) => {
  const hosmanProperties = comparableSales.filter((cSale) => ['awaiting_sale_agreement', 'awaiting_notarial_deed', 'sold'].includes(cSale.sale_state))

  return (
    <div className='flex flex-col gap-10 text-ih-indigo bg-hosman-gradient-1' data-testid='SoldProperties'>
      <div className='w-max mx-auto'>
        <p className='h2'>Découvrez les biens vendus <span className='text-ih-blue'>dans votre secteur</span></p>
      </div>

      {similarSalesScreenshots.length > 0 &&
        <div className='flex flex-col gap-5'>
          <div className='w-max mx-auto'>
            <p className='h3 text-xl fsize-22'>Vendus par d'autres agences</p>
          </div>

          <div className='flex flex-col gap-8 p-3 rounded'>
            {similarSalesScreenshots.filter(screenshot => screenshot.source === 'inventory').length > 0 &&
              <div className='flex flex-col gap-3'>
                {similarSalesScreenshots.filter(screenshot => screenshot.source === 'inventory').map((screenshot) => {
                  return (
                    <img key={screenshot.id} src={screenshot.screenshot_url.replace('.png', '.webp')} className='md:w-2/3 mx-auto rounded' />
                  )
                })}

                <p className='h4 text-center'>Source : Meilleurs Agents - Prix affiché net vendeur</p>
              </div>
            }

            {similarSalesScreenshots.filter(screenshot => screenshot.source === 'patrim').length > 0 &&
              <div className='flex flex-col gap-3 px-6'>
                {similarSalesScreenshots.filter(screenshot => screenshot.source === 'patrim').map((screenshot) => {
                  return (
                    <img key={screenshot.id} src={screenshot.screenshot_url.replace('.png', '.webp')} className='mx-auto' />
                  )
                })}

                <p className='h4 text-center'>Source: Base de données des valeurs foncières - Prix affiché net vendeur</p>
              </div>
            }
          </div>
        </div>
      }

      {hosmanProperties.length > 0 &&
        <div className='flex flex-col gap-5'>
          <div className='h3 text-xl flex items-center mx-auto fsize-22'>
              <div>Vendus avec </div>
              <HosmanLogo width={100}/>
            </div>
          <HosmanProperties
            sale={sale}
            comparableSales={hosmanProperties}
          />
        </div>
      }
    </div>
  );
}

export default SoldProperties;

SoldProperties.propTypes = {
  sale: PropTypes.object.isRequired,
  comparableSales: PropTypes.array,
  similarSalesScreenshots: PropTypes.array,
};
