import React from 'react';
import PropTypes from 'prop-types';

import YanportProperties from '../components/YanportProperties';
import HosmanProperties from '../components/HosmanProperties';
import { HosmanLogo } from 'hosman-material';

const ForSaleProperties = ({ sale, ypProperties, comparableSales }) => {
  const hosmanProperties = comparableSales.filter((cSale) => cSale.sale_state === 'awaiting_offer')

  return (
    <div className='flex flex-col gap-6 text-ih-indigo' data-testid='ForSaleProperties'>
      <div className='flex flex-col gap-10'>
        <p className='h2 text-center md:w-1/2 mx-auto'>Découvrez les biens en vente <span className='text-ih-blue'>dans votre secteur</span></p>
        {ypProperties.length > 0 &&
          <div className='flex flex-col gap-5'>
            <div className='w-max mx-auto'>
              <p className='h3 text-xl'>En vente avec d'autres agences</p>
            </div>
            <YanportProperties properties={ypProperties} />
          </div>
        }

        {hosmanProperties.length > 0 &&
          <div className='flex flex-col gap-5'>
            <div className='h3 text-xl flex items-center mx-auto'>
              <div>En vente avec </div>
              <HosmanLogo width={100}/>
            </div>
            <HosmanProperties
              sale={sale}
              comparableSales={hosmanProperties}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default ForSaleProperties;

ForSaleProperties.propTypes = {
  properties: PropTypes.array,
  comparableSales: PropTypes.array
};
