import React from 'react';
import PropTypes from 'prop-types';
import { timeSince } from '../../../../../utils/formattedDates';

import PropertyCard from '../../../../../components/Property/PropertyCard';
import { Label } from 'hosman-material';

const YanportProperties = ({ properties }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-6 justify-around mx-auto w-4/5'>
      {properties.map((property) => {
        return (
          <div key={property.id}>
            <PropertyCard
              property={{
                pictures: property.features.visual.images,
                street_name: property.address.quarter_name || property.address.city,
                kind: property.type.toLowerCase(),
                floor: property.features.geometry.floors[0]?.level,
                total_floor: property.features.geometry.floorCount === 0 ? property.features.geometry.floors[0]?.level : property.features.geometry.floorCount,
                carrez_area: property.features.geometry.surface,
                room_number: property.features.geometry.roomCount,
                bedroom_number: property.features.geometry.areaCount['BATHROOM']
              }}
              centerLabel={<Label color='bg-ih-indigo'>Publié {timeSince(new Date(property.marketing.publicationStartDate))}</Label>}
              price={property.marketing.price}
              areaPrice
              clickable={false}
            />
          </div>
        )
      })}
    </div>
  );
}

export default YanportProperties;

YanportProperties.propTypes = {
  properties: PropTypes.array
};
